import Avatar from '@component/avatar'
import OfficeLine from '@component/card/listings/office/line'
import Tooltip from '@component/tooltip'
import IconCopy from '@icon/house/copy.svg'
import { global } from '@store/index'
import { blockNumber } from '@utils'
import ClipboardJS from 'clipboard'
import { useEffect, useRef } from 'react'
import { useBoolean } from 'react-use'
import { useRecoilValue } from 'recoil'

export default function VillageCard({
  data
}) {
  const { mobile } = useRecoilValue(global)
  const [showPhone, togglePhone] = useBoolean(false)
  const [copySuccess, toggleCopySuccess] = useBoolean(false)
  const copyRef = useRef(null)

  useEffect(() => {
    if (!copyRef.current || !showPhone) return

    const clipboard = new ClipboardJS(copyRef.current, {
      text: () => data.villageContactPhone
    })
    let timer
    clipboard.on('success', () => {
      toggleCopySuccess(true)
      clearTimeout(timer)
      timer = setTimeout(() => {
        toggleCopySuccess(false)
      }, 3000)
    })

    return () => {
      clipboard.destroy()
    }
  }, [data, copyRef, showPhone, toggleCopySuccess])

  function copyHandle(event) {
    event.preventDefault()
  }

  return (
    <div className='flex items-center gap-x-16'>
      {
        data.villageLogoDetail && (
          <Avatar src={data.villageLogoDetail} className='flex-shrink-0' alt={data.villageContactName} width={64} height={64} circle defaultIcon='icon-avatar' />
        )
      }
      <div className='flex flex-col gap-10 flex-1 min-w-0'>
        {
          data.villageContactName && (
            <div>{data.villageContactName}</div>
          )
        }
        {
          data.villageContactPhone && (
            <Tooltip zIndex={201} active={copySuccess} disabled bottom={mobile} right={!mobile} content={<div className='text-secondary text-md px-10 py-2'>Copied!</div>}>
              <a href={`tel:${data.villageContactPhone}`} type='button' className='button-primary-out h-32' onClick={() => togglePhone(true)} data-noprogress>
                <i className='icon icon-phone text-primary'></i>
                <span className='ml-8'>{showPhone ? data.villageContactPhone : blockNumber(data.villageContactPhone)}</span>
                {
                  showPhone && (
                    <>
                      <i className='w-1 h-14 bg-primary ml-8'></i>
                      <button className='cursor-pointer h-full px-8 -mr-8' onClick={copyHandle} ref={copyRef}>
                        <IconCopy width={14} height={14} fill='var(--color-theme)' className='block'></IconCopy>
                      </button>
                    </>
                  )
                }
              </a>
            </Tooltip>
          )
        }
      </div>
      <OfficeLine data={data} className='rounded-sm hidden sm:flex' center iconHeight={36} />
    </div>
  )
}