'use client'

import Menu from '@component/menu'
import getRouter, { URLS, estimateMap } from '@router'
import classNames from 'classnames'
import ActiveLink from '@component/activeLink'
import { LAST_ESTIMATE_MAP_SLUG_KEY } from '@config'
import { useMount } from 'react-use'
import { useState } from 'react'
import { usePathname } from 'next/navigation'

export default function HeaderNav({
  className
}) {

  const pathname = usePathname()
  const findPropertyMenu = [
    <HeaderPopupNavItem href={getRouter(URLS.homepage)} key='Residential' isActive={pathname === '/'}>Residential</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.newhomespage)} key='New Homes'>New Homes</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.commercialHomepage)} key='Commercial'>Commercial</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.ruralHomepage)} key='Rural'>Rural</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.businessesHomepage)} key='Businesses'>Businesses</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.rentHomepage)} key='Rental'>Rental</HeaderPopupNavItem>,
    // <HeaderPopupNavItem href={getRouter(URLS.retirementLivingHomepage)} key='Retirement Living'>Retirement Living</HeaderPopupNavItem>
  ]
  const tooglsMenu = [
    <HeaderPopupNavItem href={getRouter(URLS.agentHomepage)} key='Find an agent'>Find an agent</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.soldHomepage)} key='Find a sold property'>Find a sold property</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.suburbHomepage)} key='Find a suburb'>Find a suburb</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.firsthomebuyers)} key='First home buyers'>First home buyers</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.propertyReport)} key='Property report'>Property report</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.housePriceReport)} key='House price report'>House price report</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.sellersGuide)} key='Home sellers guide'>Home sellers guide</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.newBuildsGuide)} key='New-builds guide'>New-builds guide</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.homesWeLove)} key='Homes We Love'>Homes We Love</HeaderPopupNavItem>,
    // <HeaderPopupNavItem href={getRouter(URLS.homeInsuranceChecklists)} key='Home Insurance Checklists'>Home Insurance Checklists</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.homeStyling)} key='Home Styling'>Home Styling</HeaderPopupNavItem>,
    <HeaderPopupNavItem href={getRouter(URLS.homeLoans)} key='Home Loans'>Home Loans</HeaderPopupNavItem>,
  ]

  // 估价地图最后一次使用的slug
  const [estimateHref, setEstimateHref] = useState(getRouter(URLS.estimateHomepage))
  useMount(() => {
    setTimeout(() => {
      const lastEstimateSlug = JSON.parse(localStorage.getItem(LAST_ESTIMATE_MAP_SLUG_KEY))
      if (lastEstimateSlug && lastEstimateSlug.slug) {
        setEstimateHref(estimateMap(lastEstimateSlug.slug, lastEstimateSlug.searchParams))
      }
    }, 0)
  })
  return (
    <nav className={classNames('min-w-0', className)}>
      <ul className='px-10 flex items-center justify-between whitespace-nowrap gap-x-20 md:gap-x-30 lg:gap-x-64 sm:px-0 sm:justify-start'>
        <HeaderNavItem>
          <Menu left data={findPropertyMenu} width={200} contentClassName='grid gap-y-2 py-10'>
            <span className='cursor-pointer flex items-center text-sm group-hover:text-primary'>
              Find a property
              <i className='icon icon-arrow-down text-sm ml-4'></i>
            </span>
          </Menu>
        </HeaderNavItem>
        <HeaderNavItem>
          <HeaderNavLink href={estimateHref}>Estimates</HeaderNavLink>
        </HeaderNavItem>
        <HeaderNavItem>
          <HeaderNavLink href={getRouter(URLS.newsHomepage)}>News</HeaderNavLink>
        </HeaderNavItem>
        <HeaderNavItem>
          <Menu left data={tooglsMenu} width={200} contentClassName='grid gap-y-2 py-10'>
            <span className={classNames('cursor-pointer flex items-center text-sm group-hover:text-primary')}>
              Tools
              <i className='icon icon-arrow-down text-sm ml-4'></i>
            </span>
          </Menu>
        </HeaderNavItem>
      </ul>
    </nav>
  )
}

function HeaderPopupNavItem({
  href,
  children,
  isActive = true,
  ...args
}) {
  return <ActiveLink href={href} prefetch={false} className='px-14 h-30 flex items-center text-sm hover:bg-primary-light hover:text-primary' activeClassName={{ 'bg-primary-light text-primary': isActive }} {...args}>{children}</ActiveLink>
}

function HeaderNavItem({
  children
}) {
  // first:pl-0 last:pr-0 sm:px-10 md:px-30
  return <li className='group'>{children}</li>
}

function HeaderNavLink({
  href,
  children
}) {
  return <ActiveLink href={href} prefetch={false} className='px-2 h-40 flex items-center text-sm border-b-[2px] border-b-transparent transition-colors hover:border-b-primary hover:text-primary sm:h-60 md:h-72' activeClassName='!border-b-primary !text-primary'>{children}</ActiveLink>
}