'use client'

import CloseButton from '@component/closeButton'
import useEnquiry from '@hooks/useEnquiry'
import { isVillageData } from '@utils/house'
import { useMemo } from 'react'
import Agent from './card/agent'
import Builder from './card/builder'
import AgentCard from './card/houseAgent'
import Office from './card/office'
import VillageCard from './card/village'
import EnquiryForm from './form'

export default function EnquiryContent({
}) {

  const {
    house,
    agent,
    office,
    builder,
    isAgent,
    isHouse,
    isOffice,
    isBuilder,
    isDisplayHome,
    hideEnquiry
  } = useEnquiry()

  const isVillage = isVillageData(house)

  const brandCard = useMemo(() => {
    if (isVillage) {
      return <VillageCard data={house} />
    } else if (isAgent && agent) {
      return <Agent data={agent} house={house} />
    } else if (isHouse && agent) {
      return <AgentCard data={agent} house={house} />
    } else if (isOffice && office) {
      return <Office data={office} />
    } else if ((isBuilder || isDisplayHome) && builder) {
      return <Builder data={builder} />
    } else {
      return null
    }
  }, [isAgent, isHouse, isOffice, isBuilder, isDisplayHome, isVillage, office, agent, house, builder])

  return (
    <div className='w-full max-h-screen relative bg-white p-10 overflow-auto overscroll-contain sm:px-30 sm:pt-30 sm:pb-20 sm:max-w-[94vw] sm:h-auto sm:rounded-sm sm:w-[800px]'>
      <CloseButton className='absolute right-6 top-6 z-10' onClick={hideEnquiry} />
      {
        brandCard
      }
      <EnquiryForm />
    </div>
  )
}